@import "client/colors";

.VisitsPerAppVersionChart {
  margin-top: 20px;
  width: 100%;
  height: 380px;
  position: relative;

  :global {
    .loaderContainer {
      position: relative;
      min-height: 285px;
    }

    .loaderWrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
    }

    .chartPanel {
      display: flex;
      padding: 20px;

      .chartTitle {
        color: $dt-darkBlue;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
      }

      .separator {
        flex: 1;
      }
    }

    .chartBody {
      padding: 20px;
    }
  }
}

.NoDataChart {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: $dt-button-grey;
  width: 100%;
  font-style: italic;
}
