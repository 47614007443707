@import "client/colors";

.ReportedContentReports {
  .ReportItem:not(:first-child) {
    border-top: 1px solid $dt-border;
  }
}

.ReportItem {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.reportContent {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
}

.reportText {
  font-style: italic;
}

.reportAuthor {
  font-weight: bold;
}

.reportAction {
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  :global {
    .badge {
      background-color: $dt-green;
      color: #ffffff;
      text-transform: uppercase;
      padding: 8px;
      font-size: 10px;
      letter-spacing: 1px;
    }
  }
}

.reportDate {
  color: inherit;
}

.reportInfo {
  display: flex;
  gap: 8px;
  align-items: center;
}

.reportedBeforeUpdateText {
  font-size: 14px;
}

.viewOriginalCommentText {
  font-size: 14px;
  color: $dt-darkBlue;
  cursor: pointer;
  text-decoration: underline;
}

.originalCommentText {
  font-size: 14px;
  font-style: italic;
}

.linkText {
  cursor: pointer;
  color: $dt-darkBlue;
  text-decoration: underline;
}
