@import "client/colors";

.ContactInformationCard {
  color: inherit;
  min-height: 250px;
  position: relative;
  :global {
    .loaderContainer {
      height: 80px !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
    }
    img.close {
      align-self: flex-start;
    }
  }
}

.headerTopBackground {
  height: 120px;
  background-color: $dt-darkBlue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.headerContainer {
  display: flex;
  gap: 20px;
  padding: 20px;
  z-index: 1;
  width: 100%;
}

.AvatarContainer {
  // border: 1px solid $dt-darkBlue;
  background-color: $dt-background;
  flex: 0 0 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  background-blend-mode: normal;
  position: relative;
  &::after {
    box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.avatarElement {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  display: block;
}

.MainInfoContainer {
  // border: 1px solid $dt-darkBlue;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.NameElement {
  color: #ffffff;
  font-size: 28px;
}

.ByLineElement {
  color: #ffffff;
  font-size: 17px;
  opacity: 0.75;
}

.PropertyElement {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  .label {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .value {
    i {
      opacity: 0.75;
    }
  }
}

.ActionButtonsContainer {
  // border: 1px solid red;
  flex: 1;
  display: flex;
  gap: 32px;
  align-items: flex-end;
}

.actionButtonElement {
  width: 48px;
  height: 48px;
  background-color: $dt-darkBlue;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    font-size: 24px;
  }
}
